<template>
  <div>
    <b-card
      class="mt-3 wizard"
      :header="param.header"
      v-bind:class="{ buy: isItBuy, sell: isItSell }"
    >
      <b-row>
        <b-col inline class="" @keydown="toggleDrop">
          <div
            class="
              d-flex
              justify-content-center
              align-items-center
              verticalPadding
              col-12
            "
          >
            <div class="col-8 centerAlign align-self-center">
              <v-select
                inline
                class="paddingAndWidth"
                :options="securities"
                :label="param.label"
                v-model="selected"
                :filter-by="param.myFilter"
                ref="mySelect"
                :selectOnTab="true"
                :noDrop="drop"
              >
                <template
                  v-if="param.customOption"
                  #selected-option="{ name, bbg_ticker }"
                >
                  <span>{{ name }} - {{ bbg_ticker }}</span>
                </template>
                <template
                  v-if="param.customOption"
                  #option="{ name, bbg_ticker }"
                >
                  <span>{{ name }} - {{ bbg_ticker }}</span>
                </template>
              </v-select>
            </div>
            <div>
              <b-button
                inline
                v-b-popover.hover.top="
                  'Add the select option by clicking on it or pressing ENTER, to look for more options just type the first letter!'
                "
                variant="transparent"
              >
                <svg
                  width="2em"
                  height="2em"
                  viewBox="0 0 16 16"
                  class="bi bi-question-circle"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                  />
                  <path
                    d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"
                  />
                </svg>
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-table
        :items="selectedSecurities"
        :fields="param.fields"
        class="table-striped"
      >
        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
        <template v-slot:cell(actions)="row">
          <b-button size="md" @click="remove(row.index)" variant="transparent"
            ><svg
              width="1.5em"
              height="1.5em"
              viewBox="0 0 16 16"
              class="bi bi-trash"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
              />
              <path
                fill-rule="evenodd"
                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              /></svg
          ></b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import vSelect from "vue-select";
export default {
  name: "WizardComponent",
  props: {
    param: {
      type: Object,
    },
  },
  data() {
    return {
      securities: [],
      selectedSecurities: [],

      selected: "",
      drop: false,
    };
  },
  computed: {
    isItBuy() {
      return this.param.buy;
    },
    isItSell() {
      return this.param.sell;
    },
  },

  methods: {
    toggleDrop() {
      this.drop = false;
    },
    setSelected() {
      if (
        this.selectedSecurities.includes(this.selected) === false &&
        this.selected !== ""
      ) {
        this.selectedSecurities.push(this.selected);

        this.selected = "";

        this.$store.commit(`SET_${this.param.setter}`, this.selectedSecurities);
        this.drop = true;
        this.focusInput();
      }
    },
    focusInput() {
      this.$refs.mySelect.$refs.search.focus();
    },
    remove(value) {
      this.selectedSecurities.splice(value, 1);
      this.$store.commit(`SET_${this.param.setter}`, this.selectedSecurities);
    },
    newApiCall() {
      //cambiar para cuando traigo securities, a response.data.data.rows
      this.param.getter.then((results) => {
        this.securities = results[0];
      });
    },
  },
  mounted() {
    this.newApiCall();
    window.addEventListener("keydown", (e) => {
      var key = e.which || e.keyCode;
      if (key === 13) {
        this.setSelected();
      }
    });
    window.addEventListener("click", (e) => {
      var key = e.type;
      this.setSelected();
    });
  },
};
</script>

<style scoped lang="css">
.paddingAndWidth {
  padding: 3px;
  width: inherit;
}
.centerAlign {
  align-content: baseline;
  align-self: baseline;

  border-radius: 1rem;
}
.verticalPadding {
  padding-bottom: 3vh;

  padding-top: 3vh;
}
.card-header {
  font-weight: 500;
  font-size: 1.4rem;
}
.card-header {
  font-weight: 500;
  font-size: 1.4rem;
}
.buy > .card-header {
  background-color: var(--buy-color);
  color: aliceblue;
}
.sell > .card-header {
  background-color: var(--sell-color);
  color: aliceblue;
}
.v-select {
  background: aliceblue;
}
.b-table {
  margin-top: 8vh;
}
.verticalPadding:hover {
  background-color: #d697581e;
}
</style>
